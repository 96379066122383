<template>
  <div class="vip-content-container">
    <div class="vip-bg">
      <div class="part-01">
        <div class="page-title">
          <img
            src="../../assets/images/km/VIP-club.png"
            alt=""
            class="title-img"
          />
          <p class="title-item">
            <span class="title">Hệ thống hội viên</span>
          </p>
          <p class="title-text">
            <span>Thêm bất ngờ</span> / <span>Thêm đặc quyền</span> /
            <span>Chờ bạn khám phá</span>
          </p>
          <a href="#decId" class="btn-learn">Tìm hiểu thêm</a>
        </div>
        <div id="decId" class="title-box">
          <div class="list-number">01</div>
          <div class="text-box">
            <div class="text">CẤP ĐỘ HỘI VIÊN</div>
            <div class="line-up"></div>
            <div class="line-down"></div>
          </div>
        </div>
        <div class="table-box" style="margin-top: 70px">
          <table border="1" width="100%">
            <thead>
              <tr class="first-ch">
                <th>
                  <div class="th-item">
                    <!---->
                    <span></span>
                  </div>
                </th>
                <th colspan="5">
                  <div class="th-item">
                    <img
                      src="../../assets/images/km/vip1.png"
                      class="icon-img"
                    />
                    <span>HỘI VIÊN THƯỜNG</span>
                  </div>
                </th>
                <th colspan="4">
                  <div class="th-item">
                    <img
                      src="../../assets/images/km/vip2.png"
                      class="icon-img"
                    />
                    <span>HỘI VIÊN ĐỒNG</span>
                  </div>
                </th>
                <th colspan="4">
                  <div class="th-item">
                    <img
                      src="../../assets/images/km/vip3.png"
                      class="icon-img"
                    />
                    <span>HỘI VIÊN BẠC</span>
                  </div>
                </th>
                <th colspan="4">
                  <div class="th-item">
                    <img
                      src="../../assets/images/km/vip4.png"
                      class="icon-img"
                    />
                    <span>HỘI VIÊN VÀNG</span>
                  </div>
                </th>
                <th colspan="4">
                  <div class="th-item">
                    <img
                      src="../../assets/images/km/vip5.png"
                      class="icon-img"
                    />
                    <span>HỘI VIÊN DIAMOND</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cấp độ VIP</td>
                <td>VIP 0</td>
                <td>VIP 1</td>
                <td>VIP 2</td>
                <td>VIP 3</td>
                <td>VIP 4</td>
                <td>VIP 5</td>
                <td>VIP 6</td>
                <td>VIP 7</td>
                <td>VIP 8</td>
                <td>VIP 9</td>
                <td>VIP 10</td>
                <td>VIP 11</td>
                <td>VIP 12</td>
                <td>VIP 13</td>
                <td>VIP 14</td>
                <td>VIP 15</td>
                <td>VIP 16</td>
                <td>VIP 17</td>
                <td>VIP 18</td>
                <td>VIP 19</td>
                <td>VIP 20</td>
              </tr>
              <tr>
                <td>Cược tích lũy</td>
                <td>-</td>
                <td>10K</td>
                <td>30K</td>
                <td>50K</td>
                <td>100K</td>
                <td>300K</td>
                <td>500K</td>
                <td>700K</td>
                <td>1TR</td>
                <td>3TR</td>
                <td>5TR</td>
                <td>7TR</td>
                <td>10TR</td>
                <td>30TR</td>
                <td>50TR</td>
                <td>70TR</td>
                <td>100TR</td>
                <td>300TR</td>
                <td>500TR</td>
                <td>700TR</td>
                <td>1TỶ</td>
              </tr>
              <tr>
                <td>Thưởng thăng cấp</td>
                <td>-</td>
                <td>28</td>
                <td>38</td>
                <td>58</td>
                <td>88</td>
                <td>188</td>
                <td>288</td>
                <td>388</td>
                <td>588</td>
                <td>688</td>
                <td>888</td>
                <td>1088</td>
                <td>1288</td>
                <td>1588</td>
                <td>1888</td>
                <td>2888</td>
                <td>5888</td>
                <td>6888</td>
                <td>8888</td>
                <td>18888</td>
                <td>58888</td>
              </tr>
            </tbody>
          </table>
          <p class="note">*cược tích lũy tính theo điểm | 1 điểm = 1,000 VND</p>
        </div>
        <div class="info">
          <p class="text">
            1. Hội viên của câu lạc bộ VIP sẽ được hệ thống cập nhật tự động một
            khi đạt được đủ lượng tiền cược yêu cầu.
          </p>
          <p class="text">
            2. Thứ hạng của hội viên câu lạc bộ VIP không thể chuyển đổi cho
            người khác.
          </p>
          <p class="text">
            3. Nhà cái có quyền điều chỉnh cấp độ VIP phù hợp cho từng hội viên,
            mỗi cấp độ VIP khác nhau sẽ được hưởng những đặc quyền riêng biệt
            phù hợp với cấp độ hội viên đó.
          </p>
          <p class="text">
            4. Toàn bộ hoạt động của câu lạc bộ sẽ được điều hành và giải thích
            từ phía nhà cái.
          </p>
        </div>
        <div class="title-box" style="margin-top: 70px">
          <div class="list-number">02</div>
          <div class="text-box">
            <div class="text">HOÀN TRẢ MỖI NGÀY</div>
            <div class="line-up"></div>
            <div class="line-down" style="right: 50px"></div>
          </div>
        </div>
        <div class="text-box">
          <p class="text-line-1">HOÀN TRẢ KHÔNG GIỚI HẠN</p>
          <p class="text-line-2">KHÔNG BỊ GIỚI HẠN VÒNG CƯỢC</p>
        </div>
        <div
          class="table-box"
          style="margin-top: 70px; text-transform: uppercase"
        >
          <table border="1" width="100%">
            <thead>
              <th>HOÀN TRẢ NGÀY</th>
              <th>SLOTS</th>
              <th>BẮN CÁ</th>
              <th>LIVE CASINO</th>
              <th>GAME VIỆT</th>
              <th>THỂ THAO</th>
              <th>ESPORT</th>
              <th>XỔ SỐ NHANH</th>
              <th>VÒNG CƯỢC</th>
            </thead>
            <tbody>
              <tr>
                <td>Hội viên thường</td>
                <td>0.6%</td>
                <td>0.6%</td>
                <td>0.4%</td>
                <td>0.6%</td>
                <td>0.5%</td>
                <td>0.5%</td>
                <td>0.5%</td>
                <td rowspan="5">0 VÒNG</td>
              </tr>
              <tr>
                <td>Hội viên Đồng</td>
                <td>0.7%</td>
                <td>0.7%</td>
                <td>0.5%</td>
                <td>0.7%</td>
                <td>0.6%</td>
                <td>0.6%</td>
                <td>0.6%</td>
                <!---->
              </tr>
              <tr>
                <td>Hội viên Bạc</td>
                <td>0.8%</td>
                <td>0.8%</td>
                <td>0.6%</td>
                <td>0.8%</td>
                <td>0.7%</td>
                <td>0.7%</td>
                <td>0.7%</td>
                <!---->
              </tr>
              <tr>
                <td>Hội viên Vàng</td>
                <td>0.9%</td>
                <td>0.9%</td>
                <td>0.7%</td>
                <td>0.9%</td>
                <td>0.8%</td>
                <td>0.8%</td>
                <td>0.8%</td>
                <!---->
              </tr>
              <tr>
                <td>Hội viên Diamond</td>
                <td>1.2%</td>
                <td>1.2%</td>
                <td>0.8%</td>
                <td>1.20%</td>
                <td>1.20%</td>
                <td>1.00%</td>
                <td>1.00%</td>
                <!---->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="line" style="margin-top: 40px">
          <div class="title">Mô tả</div>
          <div class="text"></div>
        </div>
        <div class="info">
          <p class="text">
            1. Để các VIP duy trì danh hiệu hội viên, tất cả ưu đãi trong tháng
            sẽ được đảm bảo để giúp cho hội viên đạt đủ ngưỡng tiền cược tích
            lũy.
          </p>
          <p class="text">
            2. Ưu đãi đặc biệt dành cho Siêu VIP được tính toán dựa trên số tiền
            cược tích lũy còn thiếu trong tháng để đảm bảo hội viên Siêu VIP giữ
            được danh hiệu.
          </p>
          <p class="text">
            3. Toàn bộ các ưu đãi ở trên đều yêu cầu 0 lần cược để có thể rút
            tiền.
          </p>
          <p class="text">
            4. Phía nhà cái có quyền chỉnh sửa, thực hiện, mở rộng quy mô cũng
            như hủy bỏ một số nội dung của ưu đãi.
          </p>
        </div>
        <div class="title-box" style="margin-top: 70px; width: 590px">
          <div class="list-number">03</div>
          <div class="text-box" style="width: 440px">
            <div class="text" style="width: 430px">SIÊU ĐỐI TÁC</div>
            <div class="line-up"></div>
            <div class="line-down" style="right: 145px"></div>
          </div>
        </div>
        <ul class="title-box list4" style="margin-top: 70px">
          <li class="list4-item">
            <img src="../../assets/images/km/icon01.png" class="img" />
            <p class="item-title">Tiền thưởng cao</p>
            <p class="item-text">Tiền hoàn trả ngất ngưỡng</p>
          </li>
          <li class="list4-item">
            <img src="../../assets/images/km/icon02.png" class="img" />
            <p class="item-title">Hoàn toàn miễn phí</p>
            <p class="item-text">
              Miễn phí thành đối tác khi đạt
              <span>VIP 5</span>
            </p>
          </li>
          <li class="list4-item">
            <img src="../../assets/images/km/icon03.png" class="img" />
            <p class="item-title">Cược dễ dàng</p>
            <p class="item-text">Chỉ cần nhấn nút là trở thành VIP ngay</p>
          </li>
        </ul>
        <div
          class="table-box"
          style="margin-top: 70px; text-transform: uppercase"
        >
          <table border="1" width="100%">
            <tr class="table4">
              <td rowspan="2">Tiền hoa hồng (dựa trên tiền cược)</td>
              <td rowspan="1">SLOTS</td>
              <td rowspan="1">GAME BÀI</td>
              <td rowspan="1">BẮN CÁ</td>
              <td rowspan="1">LIVE CASINO</td>
              <td rowspan="1">THỂ THAO</td>
              <td rowspan="1">ESPORT</td>
              <td rowspan="1">XỔ SỐ</td>
              <td rowspan="1">XỔ SỐ NHANH</td>
            </tr>
            <tr>
              <td>0.2%</td>
              <td>0.25%</td>
              <td>0.2%</td>
              <td>0.10%</td>
              <td>0.12%</td>
              <td>0.12$</td>
              <td>0.05%</td>
              <td>0.12%</td>
            </tr>
          </table>
        </div>
        <div class="title-box" style="margin-top: 70px; width: 590px">
          <div class="list-number">04</div>
          <div class="text-box" style="width: 440px">
            <div class="text" style="width: 430px">ĐIỀU KIỆN ĐIỀU KHOẢN</div>
            <div class="line-up"></div>
            <div class="line-down" style="right: 145px"></div>
          </div>
        </div>
        <div
          class="info"
          style="
            background: rgba(255, 255, 255, 0.1);
            border-radius: 6px;
            font-size: 16px;
            padding: 32px 13px;
          "
        >
          <p class="text">
            <span style="color: rgb(235, 180, 2)">1.</span>
            Các đặc quyền và siêu đặc quyền của VIP chỉ có hiệu lực sau khi đã
            nhận xong ưu đãi từ các khuyến mãi trước, và sẽ không cộng dồn với
            những khuyến mãi trước đó.
          </p>
          <p class="text">
            <span style="color: rgb(235, 180, 2)">2.</span>
            Nếu một hội viên lợi dụng các điều khoản và đặc quyền của VIP, cố ý
            khai thác các ưu ái cho hội viên trong điều khoản hoặc sử dụng đặc
            quyền để gây ảnh hưởng đến những người chơi khác, phía nhà cái có
            quyền lập tức thu hồi đặc quyền.
          </p>
          <p class="text">
            <span style="color: rgb(235, 180, 2)">3.</span>
            Chuỗi hoạt động dành cho VIP của chúng tôi chỉ áp dụng cho cùng một
            hội viên, sử dụng duy nhất một máy tính, có cùng địa chỉ IP để đảm
            bảo mọi quyền lợi cho hội viên câu lạc bộ.
          </p>
          <p class="text">
            <span style="color: rgb(235, 180, 2)">4.</span>
            Phía nhà cái có quyền thay đổi nội dung của sự kiện, cũng như mở
            rộng, hủy bỏ hoặc chấm dứt một phần hoặc toàn bộ sự kiện vào bất kì
            thời điểm và có thể không kịp thông báo trước cho hội viên. Trong
            trường hợp đó, phía nhà cái sẽ nhanh chóng gửi lời giải thích tới
            hội viên trong thời gian sớm nhất.
          </p>
          <p class="text">
            <span style="color: rgb(235, 180, 2)">5.</span>
            Ưu đãi đặc biệt của VIP sẽ được công bố trên trang sự kiện vào từng
            khoảng thời gian nhất định, hãy đón chờ trên trang sự kiện để có thẻ
            tham gia những ưu đãi sớm nhất.
          </p>
          <p class="text">
            <span style="color: rgb(235, 180, 2)">6.</span>
            Bộ phận chăm sóc dành riêng cho hội viên VIP sẽ thông báo những ưu
            đãi mới nhất dành riêng cho VIP qua điện thoại hoặc hòm thư của hội
            viên. Vì vậy hãy đảm bảo mọi thông tin bạn cung cấp cho chúng tôi
            đều chính xác để không bỏ lỡ ưu đãi nào.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.vip-content-container {
  width: 100%;
  position: relative;
  margin: 0 0;
  padding: 0 0;
  img {
    border: 0;
    vertical-align: middle;
    display: block;
  }
  .vip-bg {
    background: url(../../assets//images/km/banner-vip.png) no-repeat center;
    width: 100%;
    min-height: 3170px;
    color: #ffffff;
    font-weight: 200;
    .part-01 {
      width: 1200px;
      margin: 0 auto;
      .page-title {
        height: 870px;
        text-align: center;
        padding-top: 134px;
        .title-img {
          margin: 0 auto;
          vertical-align: middle;
          margin-bottom: 50px;
        }
        .title-item {
          font-size: 28px;
          font-weight: 400;
          color: #fff;
          text-transform: uppercase;
          margin: 0 0;
          .title {
            position: relative;
            &::before {
              content: "";
              height: 8px;
              width: 50px;
              border-top: 1px solid #fff;
              border-bottom: 1px solid #fff;
              position: absolute;
              left: -60px;
              top: 50%;
              transform: translateY(-50%);
            }
            &::after {
              content: "";
              height: 8px;
              width: 50px;
              border-top: 1px solid #fff;
              border-bottom: 1px solid #fff;
              position: absolute;
              right: -60px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .title-text {
          margin-top: 60px;
          font-size: 20px;
          font-weight: 400;
          span {
            text-transform: uppercase;
          }
        }
        .btn-learn {
          margin: 0 auto;
          margin-top: 50px;
          width: 190px;
          height: 50px;
          background-color: #f7bd01;
          font-size: 20px;
          font-weight: 400;
          color: #262626;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .title-box {
        width: 390px;
        height: 50px;
        position: relative;
        .list-number {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 60px;
          color: #ebb402;
          float: left;
          font-weight: 200;
        }
        .text-box {
          width: 340px;
          float: left;
          .text {
            width: 330px;
            height: 50px;
            line-height: 50px;
            font-size: 24px;
            text-indent: 60px;
          }
          .line-up {
            background: #ffffff;
            width: 100px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 63px;
          }
          .line-down {
            background: #ffffff;
            width: 70px;
            height: 4px;
            position: absolute;
            bottom: 0;
            right: 102px;
          }
        }
      }
      .info {
        margin-top: 30px;
        font-size: 14px;
        color: #ffffff;
        .text {
          margin: 0 0;
          line-height: 21px;
        }
        .title {
          width: 140px;
          height: 50px;
          line-height: 50px;
          background: #555556;
          border-radius: 25px;
          text-align: center;
          font-size: 18px;
          color: #ffffff;
          float: left;
        }
      }
      .list4 {
        height: 220px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 0 0;
        margin: 0 0;
        .list4-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-right: 1px solid rgba(255, 255, 255, 0.1);
          &:last-child {
               border-right: 0px solid rgba(255, 255, 255, 0.1);
          }
          .img {
            width: 114px;
            height: 114px;
          }
          .item-title {
            margin-top: 24px;
            margin-bottom: 20px;
            font-size: 18px;
            color: #e1e1e1;
          }
          .item-text {
            font-size: 16px;
            color: #c0c0c1;
          }
        }
      }
      .line {
        margin-bottom: 20px;
        width: 100%;
        height: 50px;
        .title {
          width: 140px;
          height: 50px;
          line-height: 50px;
          background: #555556;
          border-radius: 25px;
          text-align: center;
          font-size: 18px;
          color: #ffffff;
          float: left;
        }
        .text {
          float: left;
          width: 1060px;
          height: 2px;
          background: #555556;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      table {
        border-collapse: collapse;
        border-spacing: 0;
        .table4 td {
          background-color: #262626;
        }
        th {
          height: 50px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          background: #262626;
          text-transform: Uppercase;
          border: solid 1px #3a3a3b;
        }
        td {
          height: 50px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          background: #2e2e2e;
          border: solid 1px #3a3a3b;
          padding: 0;
        }
        .first-ch {
          .th-item {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-img {
              width: 29px;
              height: 29px;
              margin-right: 10px;
            }
          }
        }
      }
      .note {
        font-size: 14px;
        text-align: right;
        margin: 10px 0 0 0;
      }
    }
    .text-line-1,
    .text-line-2 {
      margin: 0 0;
      line-height: 21px;
    }
  }
}
</style>